import { mapActions } from "vuex";

export default {
  name: "terms-of-use",
  components: {},
  props: [],
  data() {
    return {
      accordion: "",
      accordionChild: "",
    };
  },
  computed: {},
  created() {
    this.setNavigation("nav-terms-of-use");
  },
  mounted() {},
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
    }),
  },
};
